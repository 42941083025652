@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@800&display=swap');

:root {
  --blue: #293DED;
  --light-violet: #756d9e;
  --violet: #220844;
  --white: #FFFFFF;
  --light-grey: #e6e5ee;
  --grey: #c1bed6;
  --pink: #f23f62;

  --default-margin: 150px;

  --footer-height: 216px;
  --navbar-height: 80px;

  --xsmall-padding: 8px;
  --small-padding: 16px;
  --medium-padding: 32px;
}

div#root:has(.navbar.active) {
  height: 100vh;
  overflow: hidden;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-user-drag: none;
}

/*------------------------- TEXTS -------------------------------*/
h1, h2, h3, h4 {
  all: unset;
  color: var(--white);
  font-family: 'Lexend', sans-serif;
  letter-spacing: 0;
  text-align: left;
}

h1 {
  font-size: 80px;
  font-weight: 900;
  line-height: 60px;
}

h2 {
  font-size: 45px;
  font-weight: 900;
  line-height: 54px;
}

h3 {
  font-size: 35px;
  font-weight: 800;
  line-height: 42px;
}

h4 {
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
}

p {
  color: var(--white);
  font-family: "Azeret Mono", monospace;
  font-size: 16px;
  line-height: 25px;
}

/*------------------------- COMPONENTS -------------------------------*/

/*------------------------- Button -------------------------------*/
button {
  all: unset;
  border-radius: 40px;
  font-family: 'Lexend', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0;
  padding: var(--small-padding) var(--medium-padding);
  text-align: center;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }

  &.white {
    background-color: var(--white);
    border: none;
    color: var(--blue);

    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }

  &.white-pink {
    background-color: var(--white);
    border: none;
    color: var(--blue);

    &:hover {
      background-color: var(--pink);
      color: var(--white);
    }
  }

  &.blue {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: var(--white);

    &:hover {
      background-color: transparent;
      color: var(--blue);
    }

    &:disabled {
      border: 1px solid var(--light-grey);
      background-color: var(--light-grey);
      color: var(--grey);
      cursor: not-allowed;
    }
  }
}

/*------------------------- Scroll Down Button -------------------------------*/

.scroll-down-button-container {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 130px;
  margin-left: auto;
  padding-right: var(--default-margin);
  position: relative;

  .scroll-down-button-text {
    color: var(--white);
    font-family: Azeret Mono, monospace;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    left: 50%;
    letter-spacing: 0;
    text-orientation: mixed;
    text-transform: uppercase;
    word-spacing: 6px;
    writing-mode: vertical-rl;
    animation: bounceText 0.4s infinite alternate linear;
  }

  .scroll-down-button-icon {
    animation: bounceIcon 0.4s infinite alternate linear;
    height: 32px;
    width: 20px;
  }
}

@keyframes bounceIcon {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(15px);
  }
}

@keyframes bounceText {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}

/*------------------------- AnimatedCard -------------------------------*/
.card {
  background-color: var(--white);
  box-shadow: 0 16px 24px 0 #00000040;
  border-radius: 20px;
  padding: 56px var(--medium-padding);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .icon-column {
    display: flex;
    flex: 1;
  }

  .text-column {
    display: flex;
    flex: 3;
    flex-direction: column;
    margin: auto;
    overflow: hidden;
    height: 100%;

    .column-header {
      color: var(--violet);
      transition: all 0.2s ease-out;
    }

    .column-description {
      color: var(--violet);
      font-size: 18px;
      transition: font-size 0.2s ease-out;
      overflow: hidden;
    }
  }
}

/*------------------------- Not Found -------------------------------*/

.not-found {
  height: calc(100vh - var(--footer-height));
  width: 100%;

  .not-found-container {
    display: flex;
    flex-direction: row;
    height: calc(100% - 40px);
    justify-content: center;
    padding: var(--navbar-height) var(--default-margin) 0 var(--default-margin);
  }

  .img-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }

  .error-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }

  .error-title {
    color: var(--violet);
  }

  .error-description {
    color: var(--violet);
  }

  .error-button-container {
    padding-top: var(--medium-padding);
  }
}

/*------------------------- Footer -------------------------------*/

.footer {
  background-color: var(--violet);
  display: flex;
  height: var(--footer-height);
  justify-content: space-between;
  width: 100%;

  .company-name-column {
    padding-left: 100px;
    margin: auto 0;
    width: 122px;
  }

  .social-media-column {
    margin: auto var(--default-margin) auto 0;

    .icons {
      display: flex;
      gap: var(--xsmall-padding);
      height: 25px;
      justify-content: flex-end;
      width: 100%;

      .instagram, .facebook, .linkedin, .twitter {
        width: 25px;
        height: 25px;
      }
    }

    .copyright-text {
      text-align: center;
    }
  }
}

/*------------------------- LanguageToggle -------------------------------*/
.language-toggle {
  display: inline-block;
  margin-right: 15px;
  position: relative;

  .es, .en {
    position: absolute;
    top: 12px;
    pointer-events: none;
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 12px;
    width: 50%;
    text-align: center;
    color: var(--violet);

    &.disabled {
      color: var(--white);
    }
  }

  .es {
    left: 0;
    padding-left: 2px;
  }

  .en {
    right: 0;
    padding-right: 2px;
  }

  .toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  .toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  .toggle + label {
    width: 76px;
    height: 38px;
    border-radius: 60px;
  }

  .toggle + label:before, .toggle + label:after {
    display: block;
    position: absolute;
    content: "";
  }

  .toggle + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    border: 1px solid var(--white);
    border-radius: 60px;
  }

  .toggle + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 33px;
    background-color: var(--white);
    border-radius: 52px;
    transition: margin 0.2s;
  }

  .toggle:checked + label:after {
    margin-left: 35px;
  }

}

/*------------------------- LogosCarrousel -------------------------------*/
.horizontal-carrousel {
  display: flex;
  overflow: hidden;
  width: 100%;

  .logos-container {
    display: flex;
    animation: horizontalScroll 40s linear infinite;
    white-space: nowrap;

    .logo {
      height: 70px;
      margin-left: 2rem;
    }
  }
}

@keyframes horizontalScroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

/*------------------------- Upload Resume -------------------------------*/


.upload-resume-container {
  padding-right: 15px;
  padding-top: 10px;
}

#fileLabel {
  color: var(--blue);
  padding: 10px;
  cursor: pointer;
  display: inline-block;

  font-family: Lexend, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.join-us-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

/*------------------------- US Card -------------------------------*/

.us-roles-container {
  background-color: var(--white);
  background-size: cover;

  .us-card-container {
    position: relative;
    /* background pink in the top 70% and white in the bottom 30%
        the top pink part has its bottom right corner rounded
    */
    background: var(--pink);
    border-bottom-right-radius: 250px;
    margin-bottom: 90px;

    .bottom-left-circle {
      position: absolute;
      bottom: 0;
      height: 12rem;
      width: 12rem;
      background-image: url("../media/backgrounds/background-us-logo-bottom-left-circle.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .bottom-left-small-blue-square {
      position: absolute;
      bottom: 150px;
      left: 80px;
      transform: rotate(45deg);
      background: var(--blue);
      height: 2rem;
      width: 2rem;
    }

    .top-blue-semicircle-and-circle {
      position: absolute;
      top: 120px;
      right: 4%;

      .top-blue-semicircle {
        position: absolute;
        top: 0;
        right: 20px;
        height: 13rem;
        width: 13rem;
        background-image: url("../media/backgrounds/background-us-blue-semicircle.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }

      .top-transparent-circle {
        z-index: 2;
        position: absolute;
        top: 140px;
        right: 110px;
        height: 8rem;
        width: 8rem;
        background-image: url("../media/backgrounds/background-us-transparent-circle.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .top-grey-squares {
      position: absolute;
      top: 120px;
      right: 32%;

      .top-right-grey-square {
        position: absolute;
        top: 0;
        transform: rotate(45deg);
        background: var(--light-grey);
        height: 30px;
        width: 30px;
      }

      .top-right-grey-square-2 {
        position: absolute;
        top: 42px;
        transform: rotate(45deg);
        background: var(--light-grey);
        height: 30px;
        width: 30px;
      }
    }

    .us-card-container-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 100px var(--default-margin) 0 var(--default-margin);

      .us-info {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        padding-top: 65px;
        padding-bottom: 35px;
      }

      .us-title {
        display: block;
        width: 50%;
      }

      .us-description {
        width: 60%;
      }

      .us-card {
        width: 850px;
        height: 388px;
        gap: 0;
        border-radius: 20px;
        background-color: var(--blue);
        display: flex;
      }

      .carousel-indicators {
        display: flex;
        flex-direction: row;
        margin: 10px auto;
        padding: 0;
        justify-content: center;
      }
    }
  }
}

.react-multi-carousel-list {
  border-radius: 20px;
}

.carousel-container {
  overflow: hidden;
  position: relative;
  margin: 40px auto 10px auto;
  width: 60%;
}

.carousel-slide {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
  transition: transform 0.4s ease-in-out;
  user-select: none;
}

.carrousel-slide-container {
  overflow: hidden;
  width: auto;
  height: 100%;
}

.carousel-slide img {
  height: auto;
  flex: 0 0 auto;
  width: 60%;
}

.carousel-slide .solutions-button-icon {
  width: inherit;
}

.solutions-slide .project-image-overlay {
  width: 100%;
}

.carousel-indicators {
  padding-bottom: 5%;
  width: 100%;
  text-align: center;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background: rgba(117, 109, 158, 0.3);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #756D9E;
}

.us-card-wrapper-container {
  padding: 0 10px;
  height: 100%;
}

.us-card-wrapper {
  border-right: 20px;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  width: 100%;

  .us-image-render {
    border-radius: 20px 0 0 20px;
    max-height: 400px;
  }
}

.us-text-content-container {
  display: flex;
  justify-content: center;
}

.us-text-content {
  font-size: 25px;
  line-height: 30px;
  margin: auto 70px;
}

.partners-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.us-partner-cards-container {
  padding: 10vh var(--default-margin) var(--navbar-height);
  display: flex;
  flex-direction: column;
}

.us-roles-title {
  color: var(--violet);
  margin-bottom: 40px;
}

.request-to-join-us-section {
  background-image: url("../media/backgrounds/background-us-as-partners.svg");
  background-size: cover;
  width: 100%;
}

.request-to-join-us-container-wrapper {
  padding: var(--navbar-height) var(--default-margin);

  .request-to-join-us-container {
    width: 100%;

    .request-to-join-description {
      margin-bottom: 50px;
    }

    .request-to-join-us-form {
      background-color: var(--white);
      border-radius: 20px;
      box-shadow: 0 16px 24px 0 #00000040;
      padding: 64px 72px;
    }

    .send-button-container {
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }
  }
}


/*------------------------- Partner Serious Card -------------------------------*/

.horizontal-partners-carrousel {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 2%;
  padding-bottom: 5%;

  .partner-logos-container {
    gap: 20px;
    display: grid;
    grid-template-rows: repeat(2, 1fr); /* Create two rows */
    grid-auto-flow: column; /* Distribute items column-wise */

    transition: transform 0.4s ease-in-out;
  }

  .partner-logo-item-container {
    padding: 0 10px;
  }

  .partner-logo-item {
    position: relative;
  }

  .partner-logo {
    position: relative;
    width: 100%;
    height: 368px;
    border-radius: 29px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-size: cover;
  }

  .gradient-overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(34, 8, 68, 0) 40%, #220844 100%);
    background-blend-mode: multiply;
    border-radius: 29px;
  }

  .partner-logo-text {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 25px;
    box-sizing: border-box;
    color: white;
    font-size: 12px;

    h3 {
      line-height: 32px;
    }
  }

  .carousel-indicators {
    margin: 15px auto auto auto;
    width: 100%;
    text-align: center;
  }


}

@keyframes scroll {
  0% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(-100%);
  }
}


.partner-full-name, .partner-serious-role {
  color: var(--white);
  display: flex;
  text-align: left;
}

/*------------------------- Home -------------------------------*/
.home-section {
  width: 100%;
  overflow: hidden;

  @keyframes mini_opacity_animation {
    0% {
      filter: opacity(1);
    }
    50% {
      filter: opacity(0.95);
    }
    100% {
      filter: opacity(1);
    }
  }

  &.hero {
    background: black url("../media/backgrounds/background-hero-videoframe-0.webp") no-repeat center center;
    background-size: cover;

    &.loading {
      animation: mini_opacity_animation infinite 3s;
    }
  }
}

.home-section.presentation {
  display: flex;
  flex-direction: column;
  height: 70vh;

  .presentation-container {
    margin: auto;
    max-width: 747px;
  }

  .presentation-button {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
  }
}

.home-section.hero {
  height: 100vh;

  .hero-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .hero-container {
    top: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .home-title {
    padding-left: var(--default-margin);
  }
}

.dream-team {
  padding: 0 var(--default-margin);
  align-items: center;
  background-color: var(--violet);
  display: flex;
  flex-direction: row;
  width: auto;
  min-height: 500px;
  gap: 50px;

  .dream-team-pills {
    flex-grow: 2;
  }

  .dream-team-contact {
    justify-content: center;
    max-width: 400px;
  }

  .dream-team-title {
    color: var(--blue);
  }

  .dream-team-button {
    margin-top: 54px;
  }
}

.why-us {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  .why-us-title {
    margin-bottom: 50px;
    text-align: center;
  }

  .why-us-card-container {
    padding: 0 var(--default-margin) 30px var(--default-margin);
  }

  .why-us-card-container-row {
    align-items: center;
    display: flex;
    flex-direction: column;

    .why-us-card {
      width: 80%;
    }
  }

  .light-title {
    color: var(--white);
  }

  .dark-title {
    color: var(--blue);
  }
}

.us-activities-carousel {
  width: 80%;
  max-width: 1020px;
  position: relative;
  top: 50px;

  .react-multi-carousel-dot-list {
    bottom: -40px;
  }
}

.react-multi-carousel-dot--active button {
  background: #756D9E !important;
}

.about-us {
  align-items: flex-end;
  background: url("../media/backgrounds/background-about-us.webp") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;

  .about-us-container {
    padding: var(--navbar-height) var(--default-margin) 0;
    position: relative;
    margin-bottom: 80px;

    .about-us-img-container {
      background: linear-gradient(0deg, rgba(216, 215, 213, 1) 0%, rgb(243 243 243) 100%);
      border-radius: 20px;
      padding: 80px;
      position: relative;
    }

    .about-us-img {
      border-radius: 20px;
      display: block;
      height: auto;
      width: 100%;
    }

    .about-us-info-container {
      width: 100%;
      position: relative;
    }

    .about-us-info {
      align-items: center;
      border-radius: 10px;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;

      a {
        align-self: flex-end;
      }
    }

    .left-shape {
      top: -20px;
      left: -80px;
      position: absolute;
      width: 160px;
    }

    .right-shape {
      top: 40%;
      right: -90px;
      position: absolute;
      width: 170px;
    }
  }
}

.home-presentation-why-us-container {
  background: url("../media/backgrounds/background-presentation.webp") no-repeat;
  background-size: contain;
}

.partners {
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;

  .icons-row {
    height: 160px;
    width: 160px;
    align-self: flex-start;
    background: url("../media/backgrounds/semi-circles-gray.png") no-repeat center center;
    background-size: cover;
  }

  .icons-row-four-circles {
    height: 160px;
    width: 160px;
    align-self: flex-start;
    background: url("../media/backgrounds/four-circles-grey.png") no-repeat center center;
    background-size: cover;
  }

  .partners-title {
    max-width: 60%;
    width: fit-content;
    margin-left: 150px;
    border-radius: 80px;
    padding: 42px 42px 42px 72px;
    border-color: #cdcbdd;
    border-style: solid;
    align-self: flex-start;
    color: var(--violet);
  }

  .partners-logos {
    max-width: 60%;
    margin-right: 150px;
    align-self: flex-end;
    border-radius: 80px;
    padding: 42px;
    border-color: #cdcbdd;
    border-style: solid;
  }
}

/*------------------------- Solutions -------------------------------*/
.solutions-section {
  .solutions-introduction {
    background: url("../media/backgrounds/background-solutions.svg") #e6e5ed no-repeat;
    background-size: 100%;
    height: fit-content;
    width: inherit;
    padding-bottom: 50px;

    .solutions-introduction-container {
      margin: auto;
      padding-top: 200px;
      width: 65%;
    }

    .solutions-title {
      display: block;
    }

    .solutions-text-gap {
      padding-top: var(--medium-padding);
    }

    .solutions-first-row {
      width: 100%;
    }

    .solutions-second-row {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    .solutions-card-rows {
      display: flex;
      flex-direction: column;
      gap: 70px;
      padding-top: 80px;
    }
  }

  .solutions-our-process {
    background: url("../media/backgrounds/background-process.png") no-repeat;
    background-size: cover;
    height: 95vh;
    width: inherit;
  }

  .solutions-labs {
    background: url("../media/backgrounds/background-labs.png") no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    height: 50vh;
    width: inherit;
  }

  .solutions-labs-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    height: 100%;
    margin: auto 0 auto var(--default-margin);

    .labs-title {
      color: var(--blue);
    }
  }

  .solutions-labs-ship-container {
    width: 50%;
    overflow: hidden;
  }

  .solutions-our-process-container {
    padding: 100px var(--default-margin) 0 var(--default-margin);
  }

  .solutions-our-process-header {
    padding-left: 10%;
    padding-top: 10%;
  }

  .solutions-projects {
    background-size: 100%;

    .solutions-projects-container {
      display: flex;
      flex-direction: column;
      gap: 60px;
      height: 100%;
      padding: 200px var(--default-margin) 0 var(--default-margin);
    }

    .solutions-projects-title {
      color: var(--violet);
    }

    .projects-list {
      display: flex;
      flex-direction: row;
      overflow-x: hidden;
    }
  }
}

/*------------------------- SolutionsCard -------------------------------*/

.project-card-max-width {
  max-width: 450px;
}

.solutions-card {
  border-radius: 20px;
  box-shadow: 0 16px 24px 0 #00000040;
  display: flex;
  flex-direction: column;
  padding: 28px;
  flex: 1;

  &.blue {
    background: radial-gradient(63.35% 82.88% at 8.24% 17.12%, #2F1959 0%, #5A74FC 100%);
  }

  &.violet {
    background: linear-gradient(215deg, #F23F62 0.5%, #953EA2 16%, #533DD0 60%, #293DED 100%);
  }

  .solutions-card-text {
    margin: 0;
  }

  .solutions-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--xsmall-padding);
    padding: var(--small-padding) 0;
  }

  .solutions-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    a {
      margin-top: auto;
    }
  }

  .solutions-button {
    display: flex;
    flex-direction: row;
    padding: 40px 0 0 0;
  }

  .solutions-button-text {
    color: var(--white);
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
    margin: auto;
    padding-right: 10px;
  }
}

/*------------------------- Service -------------------------------*/

.detailed-services-container {
  .detailed-services-presentation-section, .detailed-cv-services-presentation-section, .detailed-cryptography-services-presentation-section {
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 80px;
    height: 70vh;
  }

  .detailed-services-presentation-section {
    background-image: url("../media/backgrounds/background-software-services-details.webp");
    background-size: cover;
  }

  .detailed-cv-services-presentation-section {
    background-image: url("../media/backgrounds/background-cv-services.webp");
    background-size: cover;
  }

  .detailed-cryptography-services-presentation-section {
    background-image: url("../media/backgrounds/background-crypto-services.webp");
    background-size: cover;
  }

  .business-unit-title {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;

    .featured-business-units-title {
      font-family: Rubik, sans-serif;
      font-size: 150px;
      font-weight: 900;
      line-height: 150px;
      -webkit-text-stroke: 2px white;
      color: transparent;
      -webkit-background-clip: text;
      text-align: left;
      width: 80%;
    }
  }

  .service-description-container {
    background: linear-gradient(214deg, #533DD0 10%, #293DED 26%);
    height: 50vh;
    padding: 0 var(--default-margin);
    display: flex;
    flex-direction: row;
    border-bottom-right-radius: 300px;

    .service-description {
    }
  }

  .large-service-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .service-detailed-description {
    line-height: 29px;
    text-align: left;
    width: 80%;
    margin: auto;
  }
}

/*------------------------- FeaturedProjects -------------------------------*/

.featured-projects-section {
  background-color: var(--white);
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px var(--default-margin) 100px;

  .featured-projects-section-title {
    font-size: 35px;
    font-weight: 800;
    line-height: 42px;
    color: var(--violet);
  }
}

.other-solutions-section {
  background-image: url("../media/backgrounds/background-other-projects.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px var(--default-margin) 100px;
  position: relative;
}

.other-solutions-section {
  .featured-projects-section-title {
    font-size: 35px;
    font-weight: 800;
    line-height: 42px;
  }

  .title-container {
    padding-top: 10px;
    padding-bottom: 80px;
    text-align: left;
  }

  .project-title {
    color: var(--white);
  }
}

.featured-projects-section {
  .title-container {
    padding-top: 10px;
    padding-bottom: 80px;
    text-align: center;
  }

  .project-title {
    color: var(--violet);
  }
}

.carousel-featured-projects-container {
  display: flex;
  flex-direction: row;
  height: 80%;
  gap: 24px;
}

.project-card-and-image-wrapper-container {
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.other-solutions-section {
  .react-multi-carousel-list {
    padding-bottom: 70px;
  }
}

.detailed-services-container {
  .featured-projects-section {
    .react-multi-carousel-list {
      padding-bottom: 70px;
    }
  }
}

.featured-projects-section {
  .react-multi-carousel-list {
    padding-bottom: 70px;
  }
}

.project-card-and-image-wrapper {
  width: 463px;
  border-radius: 20px 0 0 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.project-image-overlay {
  width: 100%;
  border-radius: 20px;
  position: absolute;
}

.project-description-card {
  width: 90%;
  padding-top: 100px;
  border-radius: 20px;
  position: relative;
  z-index: 2;

  .solutions-button-container {
    bottom: 0;
    right: 48px;
  }

  .solutions-card {
    padding: 28px;
    min-height: 300px;
  }
}

.service-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.key-words-pill-container {
  gap: var(--xsmall-padding);
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 5%;
}

.service-small-detailed-description {
  font-family: Azeret Mono, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  text-align: left;
  padding-left: 20%;
}

.background-other-solutions-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-image: url("../media/backgrounds/background-other-solutions.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
}

.know-other-solutions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.other-solution-title-wrapper {
  padding-bottom: 80px;
  text-align: center;
}

.other-solutions-title {
  color: var(--violet);
}

.card-solution-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
  }
}

/*------------------------- Project -------------------------------*/

.project-container {
  .border-extension-blue {
    height: 680px;
    width: 100%;
    z-index: -1;
    position: absolute;
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    background: linear-gradient(155deg, #5A74FC 50%, #2F1959 90%);
  }

  .project-image {
    height: 100%;
    width: 85%;
    border-radius: 25px;
  }

  .project-image-container {
    display: flex;
    padding: 120px 0 180px;
    align-items: center;
    justify-content: center;

    .project-image-inner-container {
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  .project-info-section-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-size: cover;
    position: relative;
    top: -250px;
    padding-bottom: 30px;
    margin-bottom: -250px;
    background: #e6e5ee;
    border-top-left-radius: 380px;
    border-top-right-radius: 380px;

    .project-info-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 80%;
      max-width: 1400px;
    }

    .project-key-points {
      display: flex;
      flex-direction: column;
      padding-top: 130px;

      .project-title {
        color: var(--violet);
        padding-bottom: 5%;
      }

      .project-area-of-services {
        width: 176px;
        height: 32px;
        padding: var(--xsmall-padding) var(--small-padding) var(--xsmall-padding) var(--small-padding);
        gap: 10px;
        border-radius: 20px;
        opacity: 0.5;
      }
    }

    .project-details {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
      padding-top: 10%;

      .project-history {
        display: flex;
        flex-direction: column;

        .history-details {
          width: 654px;
          color: var(--violet);
          font-size: 14px;
          font-weight: 300;
          line-height: 21px;
          margin-bottom: 0;

          &:last-child {
            padding-bottom: 30px;
          }
        }

        .history-main-points {
          padding: var(--medium-padding);
          border-radius: 20px;
          background-color: var(--blue);
          margin-top: 30px;

          .main-points-title {
            color: var(--white);
            font-family: Lexend, sans-serif;
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
          }

          .main-points-list {
            color: var(--white);
            font-family: Azeret Mono, sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            text-align: left;
          }
        }
      }
    }
  }
}

.other-projects-section {
  background-image: url("../media/backgrounds/background-other-projects.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/*------------------------- Pill -------------------------------*/
.pill {
  background-color: var(--violet);
  border-radius: 20px;
  color: var(--white);
  opacity: 0.5;
  padding: var(--xsmall-padding) var(--small-padding);
  white-space: nowrap;
  width: fit-content;
}

/*------------------------- Modal -------------------------------*/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: white;
    padding: 80px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
  }

  .modal-icon {
    width: 90px;
    height: 90px;
  }

  .modal-title {
    color: var(--violet);
    padding-top: 20px
  }

  .modal-description {
    color: var(--violet);
    text-align: center;
  }

  .modal-button {
    padding-top: 20px;
  }
}

/*------------------------- Navbar -------------------------------*/
.navbar {
  width: 100%;
  position: fixed;
  z-index: 999;

  .navbar-container {
    background: linear-gradient(180deg, rgba(34, 8, 68, 0.2) 39%, rgba(34, 8, 68, 0) 100%);
    display: flex;
    height: var(--navbar-height);
    justify-content: space-between;
  }

  .navbar-logo-container {
    margin: 1em 0;
    text-align: center;
  }

  .navbar-main-logo {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .navbar-logo-link {
    padding-left: 100px;
    width: 122px;
  }

  .navbar-menu-container {
    display: flex;
    height: 100%;
  }

  .navbar-menu {
    display: none;
    margin: 0;
    padding: 120px var(--default-margin) 0;
    transition: height 2s ease;

    .menu-link {
      margin-bottom: 32px;
    }
  }

  .navbar-menu-image {
    display: none;
    background-image: url("../media/menu-illustration.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
  }

  .navbar-options {
    align-items: center;
    animation-name: stretch;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 100px;
    width: 175px;
  }

  &.active {
    height: 100%;
    background: var(--violet);
    z-index: 6;

    .navbar-menu {
      display: flex;
      flex-direction: column;
    }

    .navbar-menu-image {
      display: flex;
      flex-grow: 1;
    }
  }

  .navbar-burger-icon {
    cursor: pointer;
  }
}

/*------------------------- NavigationButton -------------------------------*/
.navigation-button {
  display: flex;

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }

  .left-arrow, .right-arrow {
    width: 14px;
    border: 2px;
    color: #FF6A87;
    padding: 5px;
  }

  .left-arrow {
    transform: rotate(0deg);
  }

  .right-arrow {
    transform: rotate(180deg);
  }

  .button-name {
    height: 19px;
    font-family: Lexend, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;

    &.left {
      color: var(--blue);
    }

    &.right {
      color: var(--white);
    }
  }
}

/*------------------------- ContactUs -------------------------------*/
.contact-us {
  background-repeat: no-repeat;
  background-image: url("../media/backgrounds/background-contact.svg");
  background-size: 100% auto;
  padding: 180px var(--default-margin) 60px var(--default-margin);
  display: flex;
  justify-content: center;
  flex-direction: column;

  .contact-us-title {
    padding-bottom: 56px;
  }

  .contact-us-form {
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 0 16px 24px 0 #00000040;
    padding: 64px 72px;
  }

  .form-greetings {
    color: var(--violet);
    font-family: "Azeret Mono", monospace;
    font-size: 16px;
    line-height: 25px;
  }

  .send-button-container {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }
}

/*------------------------- Form -------------------------------*/
.radio-input-container, .big-text-input-container, .text-input-container {
  padding-bottom: var(--small-padding);
  display: flex;
  gap: var(--xsmall-padding);

  .form-label, .small-form-label {
    color: var(--violet);
    font-family: "Azeret Mono", monospace;
    font-size: 16px;
    line-height: 25px;
  }

  .small-form-label {
    font-size: 12px;
  }
}

/*------------------------- FormRadioInput -------------------------------*/
.radio-input-container {
  flex-direction: column;
}

/*------------------------- FormBigTextInput -------------------------------*/
.big-text-input-container {
  flex-direction: column;

  .form-big-text-input {
    border-color: var(--grey);
    border-radius: 20px;
    color: var(--light-violet);
    font-family: "Azeret Mono", monospace;
    flex: 1;
    width: 100%;
    resize: none;
    padding: var(--small-padding);
  }
}

/*------------------------- FormTextInput -------------------------------*/
.text-input-container {
  flex-direction: row;

  .form-text-input {
    border-bottom-color: var(--grey);
    border-width: 0 0 1px;
    color: var(--light-violet);
    font-family: "Azeret Mono", monospace;
    flex: 1;
    width: 100%;
  }
}

/*------------------------- SectionLink -------------------------------*/
.section-link {
  color: var(--white);
  font-family: 'Lexend', sans-serif;
  font-size: 55px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 66px;
  text-align: left;
  transition: font-size 1s ease;

  &:hover {
    color: var(--blue);
    /*        font-size: 75px;
            font-weight: 900;
            line-height: 90px;
            animation: bounce 300ms cubic-bezier(0.68, -0.55, 0.6, 1.63) both;*/
  }

  /*    @keyframes bounce {
          0% {
              transform: scale(1);
          }
          50% {
              transform: scale(1.2);
          }
          100% {
              transform: scale(1);
          }
      }*/
}

/*------------------------- WordsVerticalRotator -------------------------------*/
.words-vertical-rotator {
  overflow: hidden;
  height: 156px;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.51);

  .static-text, .dynamic-text {
    all: unset;
    color: var(--white);
    font-family: 'Lexend', sans-serif;
    letter-spacing: 0;
    text-align: left;
  }

  .static-text {
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
  }

  .dynamic-text {
    font-size: 80px;
    font-weight: 900;
    line-height: 96px;
    display: block;
    list-style: none;
    height: 96px;
    overflow: hidden;
  }

  .dynamic-text-item {
    position: relative;
    top: 0;

    &.short-list {
      animation: moveShortList 8s infinite 1s;
    }

    &.long-list {
      animation: moveLongList 15s infinite 1s;
    }
  }
}

@keyframes moveShortList {
  0% {
    top: 0;
  }
  10% {
    top: -96px;
  }
  20% {
    top: -192px;
  }
  30% {
    top: -288px;
  }
  40% {
    top: -384px;
  }
}

@keyframes moveLongList {
  0% {
    top: 0;
  }
  10% {
    top: -96px;
  }
  20% {
    top: -192px;
  }
  30% {
    top: -288px;
  }
  40% {
    top: -384px;
  }
  50% {
    top: -480px;
  }
  60% {
    top: -576px;
  }
  70% {
    top: -672px;
  }
  80% {
    top: -768px;
  }
  90% {
    top: -864px;
  }
}
